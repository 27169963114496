<template>
  <div>
    <Layout>
      <PageHeader :title="title" :items="items" />
      <form @submit.prevent="save">
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 mx-auto">
            <div class="add_coin_form_box mb-3">
              <div class="row top-row align-items-center">
                <div class="col-xl-6 col-lg-6 col-md-6 col-12 mb-3">
                  <div class="form-group">
                    <label for="inputtext" class="form-label">Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="inputname"
                      placeholder="Name"
                      v-model.trim="$v.form.name.$model"
                    />
                    <div
                      class="error"
                      v-if="submitted && !$v.form.name.required"
                    >
                      Name is required
                    </div>
                    <div
                      class="error"
                      v-if="submitted && !$v.form.name.maxLength"
                    >
                      Name should be less than 50 characters.
                    </div>
                    <div
                      class="error"
                      v-if="submitted && !$v.form.name.minLength"
                    >
                      Name minimum length should be 2 character.
                    </div>
                  </div>
                  <!---form-group-->
                </div>
                <!--col-6 name-->

                <div class="col-xl-6 col-lg-6 col-md-6 col-12 mb-3">
                  <div class="form-group">
                    <label for="inputcurrency" class="form-label">Symbol</label>
                    <input
                      type="text"
                      class="form-control"
                      id="inputcurrency"
                      placeholder="Symbol"
                      v-model="$v.form.currency.$model"
                    />
                    <div
                      class="error"
                      v-if="submitted && !$v.form.currency.required"
                    >
                      Symbol is required
                    </div>
                    <div
                      class="error"
                      v-if="submitted && !$v.form.currency.maxLength"
                    >
                      Symbol should be less than 20 characters.
                    </div>
                    <div
                      class="error"
                      v-if="submitted && !$v.form.currency.minLength"
                    >
                      Symbol minimum length should be 2 character.
                    </div>
                  </div>
                  <!---form-group-->
                </div>
                <!--col-6 Symbol-->

                <div class="col-xl-6 col-lg-6 col-md-6 col-12 mb-3">
                  <div class="form-group">
                    <label for="selectpair" class="form-label"
                      >Select Pair</label
                    >
                    <multiselect
                      v-model="form.pair_with"
                      :show-labels="false"
                      :options="pair_with_options"
                      label="currency"
                      track-by="currency"
                      multiple
                    ></multiselect>

                    <div
                      class="error"
                      v-if="submitted && !$v.form.pair_with.required"
                    >
                      Pair is required
                    </div>
                  </div>
                </div>
                <!--col-6 pair-select-->

                <div class="col-xl-5 col-lg-5 col-md-5 col-10 mb-3">
                  <div class="form-group">
                    <label for="formimage" class="form-label"
                      >Choose Image</label
                    >
                    <small class="text-muted ms-2">
                      <strong>Note :- </strong>
                      <span class="text-danger"
                        >1. Only PNG Image. 2.Image Dimension 84x84 .</span
                      ></small
                    >
                    <input
                      class="form-control"
                      ref="fileupload"
                      type="file"
                      id="formFile"
                      accept="image/*"
                      @change="onChangeImage"
                    />
                    <div
                      class="error"
                      v-if="submitted && !$v.form.image.required"
                    >
                      Image is required
                    </div>
                  </div>
                  <!--form-group-->
                </div>
                <!--col-5 choose-image-->

                <div class="col-xl-1 col-lg-1 col-md-1 col-2 mb-3">
                  <div class="form-group">
                    <div class="currency-image">
                      <img :src="form.image" alt="" class="img-fluid" />
                    </div>
                  </div>
                  <!--form-group-->
                </div>
                <!--col-6 choose-image-->

                <div class="col-xl-6 col-lg-6 col-md-6 col-12 mb-3">
                  <div
                    class="form-group"
                    :class="{
                      'form-group--error': $v.form.decimal_currency.$error,
                    }"
                  >
                    <label for="selectbuy" class="form-label"
                      >Currency Length</label
                    >
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        id="decimal_currency"
                        placeholder="currency length"
                        @keypress="keyChange($event, decimalLength, -1)"
                        v-model="$v.form.decimal_currency.$model"
                        aria-describedby="decimal_currency"
                      />
                    </div>
                    <div
                      class="error"
                      v-if="submitted && !$v.form.decimal_currency.required"
                    >
                      Currency length limit is required
                    </div>
                  </div>
                  <!--form-group   onkeypress="return  !(event.charCode == 46)"-->
                </div>
                <!--col-6 sell-->

                <div class="col-xl-6 col-lg-6 col-md-6 col-12 mb-3">
                  <div
                    class="form-group"
                    :class="{
                      'form-group--error': $v.form.decimal_pair.$error,
                    }"
                  >
                    <label for="selectbuy" class="form-label"
                      >Pair Length</label
                    >

                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        id="decimal_pair"
                        placeholder="Decimal length"
                        @keypress="keyChange($event, decimalLength, -1)"
                        v-model="$v.form.decimal_pair.$model"
                        aria-describedby="decimal_pair"
                      />
                    </div>

                    <div
                      class="error"
                      v-if="submitted && !$v.form.decimal_pair.required"
                    >
                      Pair length limit is required

                      <!--form-group onkeypress="return  !(event.charCode == 46)"-->
                    </div>
                    <!--col-6 Min-->
                  </div>
                </div>

                <div class="col-xl-12 col-lg-12 col-md-6 col-12 mb-3">
                  <div
                    class="form-group"
                    :class="{ 'form-group--error': $v.form.address.$error }"
                  >
                    <label for="inputtext" class="form-label"
                      >Smart Contract Detail</label
                    >

                    <div class="row p-2">
                      <div class="col-md-3 mt-3">
                        <label for="inputtext" class="form-label"
                          >Contract Address</label
                        >
                        <input
                          type="text"
                          class="form-control shadow-none"
                          id="smartcontractid"
                          placeholder="Smart Contract Address"
                          v-model.trim="$v.form.address.$model"
                        />
                        <!--  -->
                        <div
                          class="error"
                          v-if="submitted && !$v.form.address.required"
                        >
                          Smart Contract Address is required.
                        </div>
                        <div
                          class="error"
                          v-if="submitted && !$v.form.address.maxLength"
                        >
                          Smart Contract Address should be less than 100
                          characters.
                        </div>
                        <div
                          class="error"
                          v-if="submitted && !$v.form.address.minLength"
                        >
                          Smart Contract Minimum Addresslength is 20 character
                        </div>
                      </div>

                      <!---form-group-->
                      <div class="col-md-3 mt-3">
                        <label for="inputtext" class="form-label"
                          >BlockChain Network</label
                        >
                        <multiselect
                          v-model.trim="form.block_currency"
                          :options="block_currency_options"
                          label="currency_symbol"
                          track-by="id"
                          @input="onChange($event)"
                          placeholder="Select currency"
                          :show-labels="false"
                        ></multiselect>
                        <div
                          class="error"
                          v-if="submitted && !$v.form.block_currency.required"
                        >
                          Block currency is required
                        </div>
                      </div>

                      <div class="col-md-3 mt-3" v-if="currencyType">
                        <label for="inputtext" class="form-label"
                          >Token Type</label
                        >
                        <multiselect
                          id="my_select"
                          v-model.trim="form.block_currency_type"
                          :options="block_currency_type_options"
                          track-by="token_type"
                          label="token_type"
                          placeholder="Select currency type"
                          :show-labels="false"
                        ></multiselect>
                        <div
                          class="error"
                          v-if="
                            submitted && !$v.form.block_currency_type.required
                          "
                        >
                          Block currency type is required
                        </div>
                      </div>

                      <div class="col-md-3 mt-3">
                        <label for="inputtext" class="form-label"
                          >Fetch Token Detail</label
                        >
                        <button
                          type="button"
                          v-b-modal.contractmodal
                          class="btn btn-info col-12 shadow-none"
                          @click="getContractData()"
                        >
                          Get Info
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-xl-6 col-lg-6 col-md-6 col-12 mb-3">
                  <div
                    class="form-group"
                    :class="{
                      'form-group--error': $v.form.buy_desc.$error,
                    }"
                  >
                    <label for="selectbuy" class="form-label"
                      >Buy description</label
                    >

                    <div class="input-group mb-3">
                      <textarea
                        class="form-control"
                        name="buy_desc"
                        id=""
                        cols="100"
                        rows="3"
                        v-model.trim="$v.form.buy_desc.$model"
                      ></textarea>
                    </div>

                    <div
                      class="error"
                      v-if="submitted && !$v.form.buy_desc.required"
                    >
                      Buy description is required
                    </div>
                  </div>
                  <!--form-group-->
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-12 mb-3">
                  <div
                    class="form-group"
                    :class="{
                      'form-group--error': $v.form.sell_desc.$error,
                    }"
                  >
                    <label for="selectsell" class="form-label"
                      >Sell description</label
                    >

                    <div class="input-group mb-3">
                      <textarea
                        class="form-control"
                        name="sell_desc"
                        id=""
                        cols="100"
                        rows="3"
                        v-model.trim="$v.form.sell_desc.$model"
                      ></textarea>
                    </div>

                    <div
                      class="error"
                      v-if="submitted && !$v.form.sell_desc.required"
                    >
                      Sell description is required
                    </div>
                  </div>
                  <!--form-group-->
                </div>

                <!-- <div v-if="form.pair_with.length != 0"> -->
                <div
                  v-for="(pair, index) in form.pair_with"
                  :key="index"
                  class="col-xl-6 col-lg-6 col-md-6 col-12 mb-3"
                >
                  <div class="form-group">
                    <label for="inputcurrency" class="form-label"
                      >Starting Price :-
                      <span style="color: red">
                        Price In {{ pair.currency }}</span
                      ></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="inputcurrency"
                      placeholder="Start Price"
                      @keypress="keyChange($event, decimalLength)"
                      onpaste="return false;"
                      v-model="pair.price"
                    />

                    <div class="error" v-if="submitted && !pair.price">
                      Start Price is required
                    </div>
                  </div>

                  <!---form-group-->
                </div>
                <!-- </div> -->
                <!--col-6 address-->
                <!--col-6 starting price-->
              </div>
              <!--row form-row-->
            </div>
            <!--main_box-->

            <div class="col-12 mt-5">
              <div class="form-group btn-submit">
                <div class="spinner-border" role="status" v-if="loading">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <button
                  type="submit"
                  class="btn btn-primary"
                  id="submit"
                  v-if="!loading"
                >
                  Submit
                </button>
              </div>
            </div>
            <!---submit button-->
          </div>
          <!--col-xl-8 col-lg-8 col-md-8-->
        </div>
        <!--row-->
      </form>

      <b-modal
        id="contractmodal"
        size="lg"
        title="Contract Detail"
        title-class="font-18"
      >
        <div class="d-flex justify-content-center">
          <div class="spinner-border" role="status" v-if="contractloading">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>

        <b-table
          striped
          hover
          :items="contractData"
          v-if="!contractloading"
        ></b-table>
      </b-modal>
    </Layout>
  </div>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import ApiClass from "../../api/api";
import currencyList from "../../jsonFiles/currencyList.json";

export default {
  page: {
    title: "AddCoin",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },

  data() {
    return {
      selectPairlen: "",
      title: "Add Coin",
      items: [
        {
          text: "Coin",
          href: "#",
        },
        {
          text: "Add Coin",
          active: true,
        },
      ],
      contractData: [],
      contractloading: true,
      submitted: false,

      // pair_with_options: [
      //   { currency: "USDT", price: 0 },
      //   { currency: "BTC", price: 0 },
      //   { currency: "BNB", price: 0 },
      //   { currency: "INR", price: 0 },
      // ],

      pair_with_options: [],
      block_currency_options: [],
      block_currency_type_options: [],
      status_options: [
        { key: "1", value: "Active" },
        { key: "0", value: "Not Active" },
      ],
      decimalLength: "8",
      new_image: "",
      loading: false,
      currencyType: false,

      form: {
        name: "",
        currency: "",
        pair_with: [],
        image: "",
        address: "",
        start_price: [],
        block_currency: "",
        block_currency_type: "",
        decimal_currency: "",
        decimal_pair: "",
        buy_desc: "",
        sell_desc: "",
      },
    };
  },
  validations: {
    form: {
      name: { required, maxLength: maxLength(50), minLength: minLength(2) },
      currency: {
        required,
        maxLength: maxLength(20),
        minLength: minLength(2),
      },
      pair_with: {
        required,
        $each: {
          price: {
            required,
          },
        },
      },
      image: { required },
      address: {
        required,
        maxLength: maxLength(100),
        minLength: minLength(20),
      },
      start_price: {
        $each: {
          required,
        },
      },
      block_currency: { required },
      block_currency_type: { required },
      decimal_currency: { required },
      decimal_pair: { required },
      buy_desc: { required },
      sell_desc: { required },
    },
  },
  async created() {
    this.pair_with_options = await currencyList;
  },
  mounted() {
    this.block_get();
  },
  methods: {
    async block_get() {
      var result = await ApiClass.getRequest("admin/block/get", true);
      //console.log("block_get=", result);
      if (result.data.status_code == 1) {
        this.block_currency_options = result.data.data;
      }
    },
    onChange(e) {
      this.form.block_currency_type = "";
      this.currencyType = true;
      this.block_currency_type_options = e.get_types;
    },
    onChangeImage(e) {
      this.new_image = e.target.files[0];
      this.form.image = URL.createObjectURL(e.target.files[0]);
    },
    async getContractData() {
      this.contractData = [];
      this.contractloading = true;
      let contract_address = this.form.address;
      let token_type = this.form.block_currency_type.token_type;

      if (contract_address != "" && token_type != "") {
        var result = await ApiClass.postRequest("admin/contract/get", true, {
          token_type: token_type,
          contract_address: contract_address,
        });
        if (result.data.status_code == 1) {
          this.contractData.push(result.data.data);
          this.contractloading = false;
        } else {
          this.contractData.push({ Result: "No Record Found" });
          this.contractloading = false;
        }
      } else {
        this.contractData.push({
          Result: "Token Type and Address field not found.",
        });
        this.contractloading = false;
      }
    },
    async save() {
      this.submitted = true;
      if (this.$v.$invalid) {
        return;
      }
      this.submitted = false;
      this.loading = true;
      let formData = new FormData();

      formData.append("name", this.form.name);
      formData.append("currency", this.form.currency);

      this.form.pair_with.forEach((par, index) => {
        formData.append("pair_with[" + index + "][currency]", par.currency);
        formData.append("pair_with[" + index + "][price]", par.price);
      });

      formData.append("address", this.form.address);
      formData.append("network_id", this.form.block_currency.id);
      formData.append("token_type", this.form.block_currency_type.token_type);

      formData.append("decimal_currency", this.form.decimal_currency);
      formData.append("buy_desc", this.form.buy_desc);
      formData.append("sell_desc", this.form.sell_desc);
      formData.append("decimal_pair", this.form.decimal_pair);

      formData.append("image", this.new_image);
      console.log("form=", this.form);

      let header = {
        "Content-Type": `multipart/form-data;boundary=${formData._boundary}`,
      };

      var result = await ApiClass.postRequest(
        "admin/list-coins/create",
        true,
        formData,
        null,
        header
      );

      if (result.data.status_code == 1) {
        this.loading = false;
        Swal.fire({
          position: "center",
          icon: "success",
          title: result.data.message,
          showConfirmButton: true,
          timer: 5000,
        });
        this.resetform();
      } else {
        this.loading = false;
        Swal.fire({
          position: "center",
          icon: "error",
          title: result.data.message,
          showConfirmButton: true,
          timer: 5000,
        });
      }
    },
    resetform() {
      this.form = {
        pair_with: "",
        currency: "",
        address: "",
        name: "",
        image: "",
        start_price: "",
      };
      this.$refs.fileupload.value = null;
    },
    keyChange(event, decimal = null, trt = 0) {
      if (trt == -1 && event.charCode == 46) {
        return event.preventDefault();
      }
      if (decimal == 0 && event.charCode == 46) {
        return event.preventDefault();
      }

      let res =
        event.charCode != 8 &&
        ((event.charCode >= 48 && event.charCode <= 57) ||
          (event.charCode == 46 && event.target.value.indexOf(".") == -1));

      if (decimal != null && res && event.target.value.indexOf(".") != -1) {
        res = event.target.value.split(".")[1].length < decimal;
      }

      return res ? res : event.preventDefault();
    },
  },
};
</script>

<style scoped>
.add_coin_form_box {
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  border-radius: 5px;
  padding: 15px 15px;
}

.form-group.btn-submit {
  text-align: center;
}

.form-group .currency-image {
  text-align: center;
  max-width: 44px;
  height: 44px;
  margin: 0 auto;
  border-radius: 26px;
  padding: 0;
  margin-top: 18px;
  margin-left: 0;
}

.form-group .currency-image img {
  max-width: 39px;
  margin-top: 5px;
}
</style>
